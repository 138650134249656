import {
    CART,
    CART_EDITING,
    CART_OVERLAY,
    CATEGORY,
    CHECKOUT,
    CHECKOUT_ACCOUNT,
    CHECKOUT_SUCCESS,
    CMS_PAGE as SOURCE_CMS_PAGE,
    CONTACT_US,
    CUSTOMER_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_ORDER,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_WISHLIST,
    FILTER,
    HOME_PAGE,
    MENU,
    MENU_SUBCATEGORY,
    MY_ACCOUNT,
    NO_MATCH,
    PDP,
    POPUP,
    PRODUCT_COMPARE,
    SEARCH
} from 'SourceComponent/Header/Header.config';

export {
    PDP,
    POPUP,
    CATEGORY,
    CUSTOMER_ACCOUNT,
    CUSTOMER_SUB_ACCOUNT,
    CUSTOMER_ACCOUNT_PAGE,
    CUSTOMER_WISHLIST,
    CUSTOMER_ORDER,
    HOME_PAGE,
    MENU,
    MENU_SUBCATEGORY,
    SEARCH,
    FILTER,
    CART,
    CART_OVERLAY,
    CART_EDITING,
    CHECKOUT,
    CHECKOUT_SUCCESS,
    CHECKOUT_ACCOUNT,
    MY_ACCOUNT,
    NO_MATCH,
    CONTACT_US,
    PRODUCT_COMPARE
};

// TODO: implement CMS_PAGE
export const CMS_PAGE = SOURCE_CMS_PAGE;
