import ContentWrapper from 'Component/ContentWrapper';
import {
    Footer as SourceFooter
} from 'SourceComponent/Footer/Footer.component';

/** @namespace ScandiPwa/Component/Footer/Component */
export class FooterComponent extends SourceFooter {
    // TODO implement logic
    renderCopyrightContent() {
        return (
            <ContentWrapper
              mix={ { block: 'Footer', elem: 'CopyrightContentWrapper' } }
              wrapperMix={ { block: 'Footer', elem: 'CopyrightContent' } }
              label=""
            >
                <span block="Footer" elem="Copyright">
                    Copyright © 2021 ELMANAR, Inc. All rights reserved.
                </span>
            </ContentWrapper>
        );
    }
}

export default FooterComponent;
